<template>
  <div id="new_login">
    <p class="login-title">ADVANCED PLANNING AND SCHEDULING</p>
    <div class="content">
      <div class="profile">

      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field class="form_field username"
          label="아이디"
          rounded dark
          hide-details
          v-model="username"
          :rules="nameRule"
          @keyup.enter="submit"
          required
        >
        </v-text-field>
        <v-text-field class="form_field password"
          label="패스워드"
          required
          rounded dark
          hide-details
          type="password"
          v-model="password"
          @keyup.enter="submit"
        >
        </v-text-field>
        <!-- <div class="actions">
          <v-btn class="action_btn sign_up" 
            depressed
          >가입하기</v-btn>
          <v-btn class="action_btn find_pw"
            depressed
          >
            비밀번호 찾기
          </v-btn>
        </div> -->
        <v-btn class="login"
          outlined dark rounded
          :disabled="!valid"
          @click="submit"
        >로그인</v-btn>
      </v-form>
    </div>

    <v-dialog content-class="select_machine"
      v-model="loginSuc"
      persistent
    >
      <v-card>

        <p class="title">장비를 선택해주세요</p>
        <!-- v-model, :items 연결 필요 -->
        <v-autocomplete class="machine_list"
          item-text="mid"
          label="장비 선택"
          return-object
        ></v-autocomplete>
        <div class="actions">
          <v-btn class="complete"
            depressed dark
            @click="$router.replace('/')"
          >확인</v-btn>
        </div>
        
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import sha256 from 'sha256'

export default {
  data () {
    return {
      userform:false,
      name:null,
      password:null,
      username:null,
      valid:true,
      nameRule: [
        v => !!v || '이름이 필요합니다.'
      ],
      loginSuc: false,
    }
  },
  beforeMount() {
    if(this.$router.currentRoute.path == '/logout'){
      this.$disconnect();
      this.$store.commit('enterprise', null);
      this.$store.commit('accessToken',null);
      this.$store.commit('enterprise_id',null);
    }
    if (this.$store.state.accessToken !=null && this.$store.state.accessToken !='null' ){
      this.$router.push('/')
    } else{
      this.userform=true
    }
  },
  methods: {
    submit() {
      if( this.$refs.form.validate() ){
        this.userform=false;
        var data ={username:this.username,password:sha256(this.password)};
        this.$http.LOGIN.login('/api/login/login',data).then(result =>{
          if(result.data.success){
            this.$store.state.username = result.data.username;
            this.$store.commit('enterprise', result.data.enterprise);
            this.$store.commit('enterprise_id', result.data.enterprise_id);
            this.$store.commit('accessToken',result.data);
            // this.loginSuc = true;
            this.$router.replace('/')
          }else{
              this.userform=true;
          }
        });
      }
    },
    clear(){
      this.$refs.testform.reset();
    },
  },
}
</script>

<style lang="scss" scoped>


  #new_login {
    width: 100%;
    height :100%;
    position: relative;
    background: white url(../assets/img/login_background.png) no-repeat bottom center;
    background-size: 100% auto;
    
    * {
      font-family: $ptd !important;
    }

    .login-title {
      color: #57b7f4;
      text-align: center;
      font-weight: 400;
      font-size: 23px;
      transform: translateX(-50%);
      position: absolute;
      top: 50px;
      left: 50%;
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .profile {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 0 auto 50px;
      background: #57b7f4 url(../assets/img/login_profile.svg) no-repeat center center;
      background-size: 100% auto;
    }

    // form
    ::v-deep .form_field {
      width: 320px;
      border-radius: 40px;
      background-color: $yhsblue !important;

      .v-input__slot {
        background-color: transparent !important;
      }
      .v-text-field__slot {
        height: 42px;

        input {
          max-height: 42px;
        }
      }
      .v-label {
        height: 42px;
        line-height: 42px !important;
        color: white !important;
        font-weight: 500;
        top: 0;

        &.v-label--active {
          top: -8px;
          color: $yhsblue !important;
        }
      }
    }
    .username {
      margin-bottom: 21px;
    }

    .actions {
      @extend .flex_row;

      padding: 16px 0 16px;

      .action_btn {
        font-size: 16px !important;
        font-weight: 300;
        letter-spacing: 0.2px;
        background: transparent !important;
      }
      .sign_up {
        color: $yhsblue !important;
        padding-left: 0 !important;
      }
      .find_pw {
        justify-self: flex-end;
        margin-left: auto;
        padding-right: 0 !important;
      }
    }
    
    .login {
      width: 100%;
      height: 42px !important;
      border: 1px solid $yhsblue !important;
      border-radius: 40px !important;
      margin-top: 32px;
      padding-top: 3px;
      color: $yhsblue !important;
      font-size: 16px !important;
    }
  }

  // 기계 선택 dialog 
  ::v-deep .select_machine {
    width: fit-content !important;

    * {
      font-family: $ptd !important;  
    }
    .title {
      width: 350px;
      text-align: center;
      padding: 12px 0;
      margin-bottom: 20px;
      color: $yhsblue;
    }
    .machine_list {
      width: 320px;
      margin: 0 auto;
    }
    .actions {
      @extend .flex_row;

      justify-content: flex-end;
      padding: 8px;

      .complete {
        background-color: $yhsblue !important;
      }
    }
  }
</style>